@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss"; 
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

.mobileifream .mobileview iframe body.sidebar-expanded .main-sidebar-sections {
  display: none !important;
}

.img-and-text-side-by-side {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.new-button-add-upload button.upload-button.px-7.py-3.btn-sm.btn-blue.text-xl.text-white.font-semibold {
  width: 286px !important;
}

/* dropdown style css  */
.main-body-section .css-qbdosj-Input {
  margin: -5px !important;
}

.organisation-basic-information {
  textarea {
    min-height: 82px !important;
    max-height: 182px !important;
  }
}

.disabled-enddate-input {
  opacity: 0.7;
  pointer-events: none;
}

.finance-button{
  width:230px;
}

